import { createTheme, ThemeOptions } from '@mui/material';

///Mui fonts and colors here
// Example
// import { Inter } from 'next/font/google';
// const inter = Inter({ subsets: ['latin'] });

const themeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: '#FF5A1E',
    },
    error: {
      main: '#F00',
    },
  },
  typography: {
    h2: { fontSize: '18px', lineHeight: '24px', color: '#323232', fontWeight: 600 },
    body1: {
      color: '#323232',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'uppercase',
          fontSize: '16px',
          lineHeight: '24px',
          fontWeight: '500',
          padding: '16px',
        },
      },
    },
  },
};

export const muiTheme = createTheme(themeOptions);
