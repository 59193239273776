import { useEffect } from 'react';
import { usePathname } from 'next/navigation';

export const setupZendesk = (pathname: string) => {
  if (typeof window.zE == 'function') {
    const isAuthPage = pathname.includes('auth');
    if (isAuthPage) {
      window.zE('messenger', 'hide');
    } else {
      window.zE('messenger', 'show');
    }
    window.zE('messenger:set', 'locale', 'ro');
  }
  return;
};

export const useZendesk = () => {
  const pathname = usePathname();
  useEffect(() => {
    const timeout = setTimeout(() => setupZendesk(pathname), 1);
    return () => clearTimeout(timeout);
  }, [pathname]);
};

export const Zendesk = () => {
  useZendesk();
  return null;
};
