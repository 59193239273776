`use client`;
import { ReactNode, useState } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ThemeProvider } from '@mui/material';
import { AppRouterCacheProvider } from '@mui/material-nextjs/v13-appRouter';
import muiTheme from '../theme';

import { ru, ro, en, bg } from 'shared/locales';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { Zendesk } from './zendesk/zendesk-script';

i18n
  .use(initReactI18next)
  .init({
    detection: {
      order: ['queryString', 'cookie'],
      caches: ['cookie'],
    },
    fallbackLng: 'en',
    lng: process.env.NEXT_PUBLIC_COUNTRY,
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: true,
    },
    resources: {
      ru: {
        translation: ru,
      },
      ro: {
        translation: ro,
      },
      en: {
        translation: en,
      },
      bg: {
        translation: bg,
      },
    },
  })
  .catch((err) => {
    console.error(err);
  });

export const Providers = ({ children }: { children: ReactNode }) => {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            staleTime: 60 * 1000,
            refetchOnWindowFocus: false,
            refetchOnMount: true,
            refetchOnReconnect: false,
          },
        },
      })
  );

  return (
    <QueryClientProvider client={queryClient}>
      <AppRouterCacheProvider>
        <Zendesk />
        <ThemeProvider theme={muiTheme}>
          <>
            {/*<InstallPWA />*/}
            {children}
          </>
        </ThemeProvider>
      </AppRouterCacheProvider>
    </QueryClientProvider>
  );
};
