'use client';

import Providers from 'shared/providers';
import { CssBaseline } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { GoogleTagManager } from '@next/third-parties/google';
import { setupZendesk } from 'shared/providers/zendesk/zendesk-script';
import { usePathname } from 'next/navigation';

export default function RootLayout({
  children,
}: Readonly<{
  children: React.ReactNode;
}>) {
  const { t } = useTranslation();
  const pathname = usePathname();

  return (
    <html lang={process.env.NEXT_PUBLIC_COUNTRY || 'en'}>
      <head>
        <title>{t('metadata.home.title')}</title>
        <meta name={'description'} content={t('metadata.home.description')} />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap"
          rel="stylesheet"
        />
        <link href="/assets/fonts/odds.css" rel="stylesheet" />
        {process.env.LOCALE !== 'ru' && (
          <script
            async
            id="ze-snippet"
            src={`https://static.zdassets.com/ekr/snippet.js?key=${process.env.NEXT_PUBLIC_ZENDESK_KEY}`}
            onLoad={() => setupZendesk(pathname)}
          />
        )}
      </head>
      <GoogleTagManager gtmId={process.env.NEXT_PUBLIC_GTM_ID || ''} />
      <body>
        <CssBaseline />
        <Providers>{children}</Providers>
      </body>
    </html>
  );
}
